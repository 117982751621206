import React, { Component, useState, useRef, useEffect } from "react";
import style from "./ProjectsSection.module.css";
import mushSimGif from "../../../public/projectGifs/mushroomSimEdit.gif";
import oitDBGif from "../../../public/projectGifs/oitDBDemoEdit.gif";
import lastwordGif from "../../../public/projectGifs/lastWordDemo.gif";
import githubLogo from "../../../public/github_logo.png";
import exitToAppIcon from "../../../public/exit_to_app_icon.png";

function ProjectsSection(props) {
  return (
    <div className={style.container}>
      <h3 className={style.header}>
        projects{" "}
        <span className={style.headerLine}>
          <hr />
        </span>
      </h3>
      <div className={style.projectsList}>
        <div className={style.projectBlock}>
          <img
            className={style.demoGif}
            src={oitDBGif}
            alt="gif of application review system demo"
          />
          <div className={style.projectDescription}>
            <h4>
              mentorship application review system{" "}
              <a
                target="_blank"
                href="https://github.com/lateral-neck-stretch/oit-mentorship-db/"
              >
                <img
                  className={style.logoIcon}
                  src={githubLogo}
                  alt="github_icon"
                />
              </a>
              <a
                target="_blank"
                href="https://mentorship-application-review-system.onrender.com/"
              >
                <img
                  className={style.logoIcon}
                  src={exitToAppIcon}
                  alt="exit_to_app_icon"
                />
              </a>
            </h4>
            <p className={style.projectSummary}>
              My first project built for a “client”, I worked with an
              <a
                target="_blank"
                href="https://outintech.com/mentorship-program/"
              >
                {" "}
                Out-in-Tech
              </a>{" "}
              mentorship program mentor to create an app that could provide a
              single interface to view mentee applications, submit
              scores/reviews, and manage candidate application status. Through
              an iterative process, I produced an alternative to the program’s
              existing, decentralized review system.
              <br />
              <br />
              <span className={style.techStack}>
                <span className={style.bold}>Technical stack: </span>Javascript,
                React.js, React Query, Redux/React-Redux, HTML/CSS, CSS Modules,
                Material UI, Node.js, Express.js, PostgreSQL, Sequelize, Google
                APIs, Render, CockroachDB
              </span>
            </p>
            <p className={style.keyFeatures}>Key features:</p>
            <ul>
              <li>
                <span className={style.listHead}>
                  Third-party API integration:
                </span>{" "}
                npm install -g firebase-tools for easier adoption, the app pulls
                existing application data from Google Sheets/Forms, normalizes
                that data, and writes it into atomic database transactions
              </li>
              <li>
                <span className={style.listHead}>Concurrent use:</span> multiple
                reviewers can add, edit, and remove reviews concurrently without
                conflict, thanks to “super many-to-many” database model
                associations and backend APIs that eager load the latest mentee
                and review data on render
              </li>
              <li>
                <span className={style.listHead}>
                  Intuitive, easy-to-use UI:
                </span>{" "}
                each page was designed using familiar Material UI design
                patterns and components that boost both form and function.
                Client state is kept synced with server state through React
                Query polling and mutations.
              </li>
            </ul>
          </div>
        </div>

        <div className={style.projectBlock}>
          <img
            className={style.demoGif}
            src={mushSimGif}
            alt="gif of mushroom simulator in use"
          />
          <div className={style.projectDescription}>
            <h4>
              mushroom simulator (npm-mush)
              <a
                target="_blank"
                href="https://github.com/lateral-neck-stretch/nodepackagemush"
              >
                <img
                  className={style.logoIcon}
                  src={githubLogo}
                  alt="github_icon"
                />
              </a>
              <a target="_blank" href="https://pocket-mush.onrender.com/">
                <img
                  className={style.logoIcon}
                  src={exitToAppIcon}
                  alt="exit_to_app_icon"
                />
              </a>
            </h4>
            <p className={style.projectSummary}>
              Initially built over a week of bootcamp in November 2022 as my
              first solo-project and gradually expanded on in the months
              following. Once initialized, the app gives you an animated pet
              mushroom selected using your geographic coordinates. Your pet
              mushroom will “grow” as it would in real time, and this growth
              “continues” even when the app isn’t running. I was inspired by
              “tamagotchi” digital pets of the early-2000s and, in the spirit of
              play, wanted to build something cute and useless.
              <br />
              <br />
              <span className={style.techStack}>
                <span className={style.bold}>Technical stack: </span>Javascript,
                React.js, Redux, HTML/CSS, CSS Modules, Node.js, Express.js,
                PostgreSQL, Sequelize, Anime.js, Geolocation API, Render
              </span>
            </p>
            <p className={style.keyFeatures}>Key features:</p>
            <ul>
              <li>
                <span className={style.listHead}>Persistent growth:</span>{" "}
                thanks to the magic of localStorage and React Effects, your pet
                mushroom will (seemingly) keep growing even if your app isn’t
                running
              </li>
              <li>
                <span className={style.listHead}>
                  Seeding from user location:
                </span>{" "}
                using JavaScript’s Geolocation API and backend APIs, the app
                will query the database using your geographic coordinates and
                choose a mushroom that grows in your region
              </li>
              <li>
                <span className={style.listHead}>Animations:</span> opening
                animations and your mushroom’s bounce powered by Anime.js, CSS
                styling, and conditional React rendering
              </li>
            </ul>
          </div>
        </div>

        <div className={style.projectBlock}>
          <img
            className={style.demoGif}
            src={lastwordGif}
            alt="gif of lastword in use"
          />
          <div className={style.projectDescription}>
            <h4>
              lastword
              <a
                target="_blank"
                href="https://github.com/lateral-neck-stretch/lastword"
              >
                <img
                  className={style.logoIcon}
                  src={githubLogo}
                  alt="github_icon"
                />
              </a>
            </h4>
            <p className={style.projectSummary}>
              Built in December 2022, my group capstone project for bootcamp.
              LastWord is a language-learning app that uses the IBM Watson API
              to offer scored live translation games. This project was an early
              learning experience in using git to bounce back from
              near-disasters in version control, continuous integration, and
              agile workflows.
              <br />
              <br />
              <span className={style.techStack}>
                <span className={style.bold}>Technical stack: </span>Javascript,
                React.js, Redux, HTML/CSS, CSS Modules, Material UI, Node.js,
                Express.js, PostgreSQL, Sequelize, IBM Watson API, Render
              </span>
            </p>
            <p className={style.keyFeatures}>Key features:</p>
            <ul>
              <li>
                <span className={style.listHead}>User profiles:</span> I
                advocated for expanding the gamification of our app and adding
                public user profiles, which I designed in Figma and turned into
                a React component
              </li>
              <li>
                <span className={style.listHead}>Up-to-date technologies:</span>{" "}
                to allow for use of Material UI components, I refactored legacy
                boilerplate code to run the latest version of React and updated
                React class components to functional components
              </li>
            </ul>
            <p className={style.keyFeatures}>Key mistakes:</p>
            <ul>
              <li>
                I spent three days writing and refactoring code without making a
                single interim pull request or merging updates from my teammates
                the whole time. One very large pull request and a few hours of
                git damage control later, lessons were learned :&#41;
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSection;
