import React from 'react';

import Header from './components/Header/Header';
import ProjectsSection from './components/ProjectsSection/ProjectsSection';
import Footer from './components/Footer/Footer';

import style from './App.module.css';

function App(props) {
  return (
    <div className={style.containerGrid}>
      <Header className={style.header} />
      <ProjectsSection className={style.projects} />
      <Footer className={style.footer} />
    </div>
  );
}

export default App;
