// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ykzyQWSadB9N3EDCuVsd{
  display: grid;
  grid-template-rows: [header] auto [projects] auto [footer] auto;
  width: 100%;
  height: 100%
}
.DFaPDspxgqu7OEcY1JRz{
  grid-area: header;
}
.ZRlOplmfbyl_4Aw6hCjL{
  grid-area: projects;
}
.g8bvUNrC66BLiDqynbYQ{
  grid-area: footer;
}`, "",{"version":3,"sources":["webpack://./client/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+DAA+D;EAC/D,WAAW;EACX;AACF;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".containerGrid{\n  display: grid;\n  grid-template-rows: [header] auto [projects] auto [footer] auto;\n  width: 100%;\n  height: 100%\n}\n.header{\n  grid-area: header;\n}\n.projects{\n  grid-area: projects;\n}\n.footer{\n  grid-area: footer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerGrid": `ykzyQWSadB9N3EDCuVsd`,
	"header": `DFaPDspxgqu7OEcY1JRz`,
	"projects": `ZRlOplmfbyl_4Aw6hCjL`,
	"footer": `g8bvUNrC66BLiDqynbYQ`
};
export default ___CSS_LOADER_EXPORT___;
