import React, { Component, useState, useRef, useEffect } from "react";
import style from "./Header.module.css";
import backgroundImage from "../../../public/backgroundPhoto_blue.png";
import githubLogo from "../../../public/github_logo.png";
import linkedinLogo from "../../../public/linkedin_logo.png";

function Header(props) {
  return (
    <div className={style.container}>
      <div className={style.topbar}>
        <span className={style.nameLogo}>
          <p className={style.nameEnglish}>ANTHONY ZHANG</p>
          <p className={style.nameChinese}>張鑫</p>
        </span>
        <div className={style.contactInfo}>
          <p className={style.emailAddress}>hello [at] anthonyzhang.info</p>
          <div className={style.contactLogos}>
            <a target="_blank" href="https://github.com/lateral-neck-stretch/">
              <img
                className={style.logoIcon}
                src={githubLogo}
                alt="blue_background_photo"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/zhangxin-anthony/"
            >
              <img
                className={style.logoIcon}
                src={linkedinLogo}
                alt="blue_background_photo"
              />
            </a>
          </div>
        </div>
      </div>
      {/* <img
        className={style.backgroundImage}
        src={backgroundImage}
        alt='blue_background_photo'
      /> */}
      <h3 className={style.aboutHeader}>
        about{" "}
        <span className={style.headerLine}>
          <hr />
        </span>
      </h3>
      <div className={style.aboutSection}>
        <p>
          Hi! I’m Anthony. I’m a software engineer based in Brooklyn, NY. I work
          primarily in JavaScript (React on the frontend; Node, Express,
          PostgreSQL on the backend). These days, I'm building a financial
          services app for small businesses with{" "}
          <a target="_blank" href="https://onebitapp.com/">
            {" "}
            ONEBIT
          </a>
          , a{" "}
          <a
            target="_blank"
            href="https://startup.google.com/latinofoundersfund"
          >
            {" "}
            Google for Startups
          </a>
          -backed startup as a full-stack engineer.
        </p>
        <p>
          I’m deeply interested in the structures—social, politico-economic, and
          technological—that form our experiences of the world. I came to
          software engineering because I want to use code as a building-block
          for structures that better center human needs and perspectives.
        </p>
        <p>
          As an engineer, I've also volunteered on open source projects with{" "}
          <a target="_blank" href="https://outintech.com/">
            {" "}
            Out-in-Tech{" "}
          </a>
          and{" "}
          <a target="_blank" href="https://www.hackforla.org/">
            {" "}
            Hack for LA
          </a>
          . In past iterations, I’ve worked for the
          <a target="_blank" href="https://www.unaids.org/en">
            {" "}
            United Nations
          </a>
          , conducted mixed-methods research on topics ranging from
          sociolinguistic cognition (funded by the{" "}
          <a
            target="_blank"
            href="https://new.nsf.gov/funding/opportunities/research-experiences-undergraduates-reu"
          >
            {" "}
            National Science Foundation
          </a>
          ) to the sociology of immigration in Taiwan (funded by{" "}
          <a
            target="_blank"
            href="https://www.pomona.edu/administration/academic-dean/funding/student-research-opportunities/surp"
          >
            {" "}
            Pomona College
          </a>
          ), and helped problem-solve with one of the world’s leading{" "}
          <a target="_blank" href="https://www.fsg.org/">
            {" "}
            social impact consultancies
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default Header;

/**
 * I'm working on volunteer and open source projects
          with
          <a target="_blank" href="https://outintech.com/">
            {' '}
            Out-in-Tech{' '}
          </a>
          and{' '}
          <a target="_blank" href="https://www.hackforla.org/">
            {' '}
            Hack for LA
          </a>
 */
