import React from 'react';
import style from './Footer.module.css';

function Footer() {
  return (
    <div>
      <div className={style.aboutTextContainer}>
        <div className={style.aboutText}>
          <p>
            about this site: this site was built from scratch with html, css,
            and a little bit of jsx. it's hosted on google firebase. thanks for
            stopping by!
          </p>
          <p>© 2023 Anthony Zhang</p>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.content}>~ =^._.^= ∫</div>
      </div>
    </div>
  );
}

export default Footer;
