// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForpYN1UxFINnJHjiM1p p{
  font-size:12px;
  font-weight:300;
  font-family: 'IBM Plex Sans', sans-serif;
}
.ForpYN1UxFINnJHjiM1p{
  width: -webkit-fill-available;
  height: fit-content;
  display: grid;
  margin-block-end: 2em;
}
.E3UO1NZN2eNkUCydVCJx{
  width: 30vw;
  justify-self:center;
  text-align:center;
}
.o6wK8_Z53vQeGBFZWOqN{
  width:100%;
  min-height:7vh;
  display:flex;
  justify-content: center;
  align-items: center;

  background: #D1291D;
  color: white;
}
.sAfBa_Pc1CnLMC7QukFA{
  display: flex;
}`, "",{"version":3,"sources":["webpack://./client/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,wCAAwC;AAC1C;AACA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,cAAc;EACd,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;;EAEnB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;AACf","sourcesContent":[".aboutTextContainer p{\n  font-size:12px;\n  font-weight:300;\n  font-family: 'IBM Plex Sans', sans-serif;\n}\n.aboutTextContainer{\n  width: -webkit-fill-available;\n  height: fit-content;\n  display: grid;\n  margin-block-end: 2em;\n}\n.aboutText{\n  width: 30vw;\n  justify-self:center;\n  text-align:center;\n}\n.container{\n  width:100%;\n  min-height:7vh;\n  display:flex;\n  justify-content: center;\n  align-items: center;\n\n  background: #D1291D;\n  color: white;\n}\n.content{\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutTextContainer": `ForpYN1UxFINnJHjiM1p`,
	"aboutText": `E3UO1NZN2eNkUCydVCJx`,
	"container": `o6wK8_Z53vQeGBFZWOqN`,
	"content": `sAfBa_Pc1CnLMC7QukFA`
};
export default ___CSS_LOADER_EXPORT___;
